exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Container-module--container--3KHiT {\n  max-width: 750px;\n  margin: 0 auto;\n  padding: 0 1rem; }\n  @media (min-width: 992px) {\n    .Container-module--container--3KHiT {\n      max-width: 970px; } }\n\n/*# sourceMappingURL=sass.map */", "", {"version":3,"sources":["/root/nazdar-website/src/components/Container/Container.module.scss","/root/nazdar-website/src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,iBCiCe;EDhCf,eAAc;EACd,gBAAe,EAAA;EAEf;IALF;MAMI,iBC6BgB,EAAA,ED3BnB;;AAFD,gCAA+B","file":"Container.module.scss","sourcesContent":[null,null],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "Container-module--container--3KHiT"
};