exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".FullScreenSection-module--fullScreenSection--3MOzW {\n  display: flex;\n  min-height: 100vh;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  z-index: 1;\n  padding: 0 0 1.5rem; }\n  .FullScreenSection-module--fullScreenSection--3MOzW .FullScreenSection-module--fullScreenSectionInner--gDGj4 {\n    width: 100%; }\n    .FullScreenSection-module--fullScreenSection--3MOzW .FullScreenSection-module--fullScreenSectionInner--gDGj4 h1,\n    .FullScreenSection-module--fullScreenSection--3MOzW .FullScreenSection-module--fullScreenSectionInner--gDGj4 h2,\n    .FullScreenSection-module--fullScreenSection--3MOzW .FullScreenSection-module--fullScreenSectionInner--gDGj4 h3 {\n      margin-top: 0; }\n\n/*# sourceMappingURL=sass.map */", "", {"version":3,"sources":["/root/nazdar-website/src/components/FullScreenSection/FullScreenSection.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAa;EACb,kBAAiB;EACjB,YAAW;EACX,oBAAmB;EACnB,wBAAuB;EACvB,mBAAkB;EAClB,WAAU;EACV,oBAAmB,EAAA;EARrB;IAWI,YAAW,EAAA;IAXf;;;MAgBM,cAAa,EAAA;;AAAnB,gCAA+B","file":"FullScreenSection.module.scss","sourcesContent":[null],"sourceRoot":""}]);

// exports
exports.locals = {
	"fullScreenSection": "FullScreenSection-module--fullScreenSection--3MOzW",
	"fullScreenSectionInner": "FullScreenSection-module--fullScreenSectionInner--gDGj4"
};