exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Col-module--col--2SqTI {\n  position: relative;\n  width: 100%;\n  min-height: 1px;\n  padding: 0 1rem; }\n  @media (min-width: 768px) {\n    .Col-module--col--2SqTI {\n      flex-basis: 0;\n      flex-grow: 1;\n      max-width: 100%; } }\n\n/*# sourceMappingURL=sass.map */", "", {"version":3,"sources":["/root/nazdar-website/src/components/Col/Col.module.scss"],"names":[],"mappings":"AAGA;EACE,mBAAkB;EAClB,YAAW;EACX,gBAAe;EACf,gBAAe,EAAA;EAEf;IANF;MAOI,cAAa;MACb,aAAY;MACZ,gBAAe,EAAA,EAElB;;AAHD,gCAA+B","file":"Col.module.scss","sourcesContent":[null],"sourceRoot":""}]);

// exports
exports.locals = {
	"col": "Col-module--col--2SqTI"
};