import * as React from 'react'
import Helmet from 'react-helmet'

import { Intro } from '../components/Intro'
import { About } from '../components/About'
import { Features } from '../components/Features'
import { Epilogue } from '../components/Epilogue'
import { News } from '../components/News'

import { Footer } from '../components/Footer'
import IndexLayout from '../layouts'
import styles from '../components/Intro/Intro.module.scss'
import privacyStyles from 'privacy.module.scss'

const IndexPage: React.FC = () => {
  return (
    <>
      <div style={{ margin: "5%" }}>

        <h1> Privacy Policy</h1>
        <p> Last Updated: [3/4/2024]
        </p>

        <p>
          Nazdar Games Inc. (“Nazdar, “we”, “us”, “our”) has created this privacy policy (“Privacy Policy”) in order to set out how we collect, use, and disclose personal information through Pegasus Payday, a skill-based, mobile game allowing players to participate in online gaming challenges, competitions, and tournaments (“App”) and supporting software (together with App, “Service”) This Privacy Policy describes our practices regarding personal information of users, customers, and prospective customers of our Service (“User”, “you”, “your”).      </p>

        <p>We may change this Privacy Policy from time to time at our sole discretion. We encourage you to review this Privacy Policy each time you use the Services to see if the Privacy Policy has been changed, as indicated by the “Last Updated” date set out above. We may require you to provide consent to the updated Privacy Policy before further use of the Service is permitted.</p>

        <h2>1. WHAT DOES THIS PRIVACY POLICY COVER
        </h2>

        <p>This Privacy Policy covers our collection, use and disclosure of information about identifiable individuals (“Personal Information”). This Privacy Policy does not apply to the practices of companies that we do not own or control.
        </p>

        <h2>2. COLLECTION AND USE OF PERSONAL INFORMATION AND OTHER DATA
        </h2>

        <h3>2.1. Account Information</h3>

        <p>In order to use the App, you must register an account (“Account”). When you register an Account, Nazdar collects your email to authenticate your Account access and provide you with access to the Service. Nazdar also uses your email address to communicate with you. Nazdar will also collect your age, IP address, and location to confirm that you are eligible to participate in certain Service features based on your location.</p>
        <p>Nazdar also uses Account information to communicate with you, provide you with updates and other information relating to the Service, and to otherwise provide customer support. We also use your contact information (collected during Account registration) for marketing and advertising purposes, such as developing and providing promotional and advertising materials that may be relevant, valuable, or otherwise of interest to you, in all cases where you have subscribed to receive such information.</p>

        <h3>2.2. Third-Party Services</h3>

        <p>We may integrate with or rely on third-party platforms and services to provide the Service to you (“Third-Party Services”). Any Personal Information submitted to us via Third-Party Services that we can access will be subject to this Privacy Policy, but please note that such Personal Information will also be subject to the terms of the applicable Third-Party Services. Please consult the relevant third party’s privacy policy to see how they process your Personal Information.</p>
        <p>In some cases, we may receive information about you from Third-Party Services. For example, we may receive information about you from our advertising partners which we use to provide personalized advertisements.</p>

        <h3>2.3. Information Shared via the Service</h3>
        <p>Certain features of the Service may allow you to share Personal Information with us. All such shared Personal Information will be subject to this Privacy Policy.</p>

        <h3>2.4. Usage Data</h3>

        <p>When you use the Service, we may collect certain information through technologies such as cookies and other automatic data collection technologies (“Cookies”). We may use Cookies for authentication and to compile aggregate statistics about usage of the Service, such as how long Users spend using the App, and what parts of the App are viewed most often. This information is used to improve the content and operation of the Service. We may also use Cookies to promote the Service through marketing and advertising. These Cookies may be accessed or disclosed to third parties for the purpose of serving you relevant advertisements. You may opt out of Cookies or prevent third-party websites from accessing our Cookies by adjusting your privacy settings on your browser. However, opting out of Cookies may disable some features of the Service and may prevent us from providing you with the information and services you have requested.</p>
        <p>When you use the Service, we may collect information about your device (“Device Information”). Some Device Information, such as unique device identifiers, may constitute Personal Information. The Personal Information we collect from Device Information can depend on the individual settings of the device you use to access the Service. We recommend checking the policies of the device manufacturer to learn about what Device Information may be shared with us. You may disable the sharing of Device Information, but doing so may disable some features of the Service and may prevent us from providing you with the information and services you have requested.</p>

        <h3>2.5. Leaderboard and Sharing of User Scores.</h3>
        <p>
          Our mobile game includes a global leaderboard feature that allows users to compare their scores with other players worldwide. To provide this functionality, we collect and upload user scores, wagered amounts, and prizes earned to our servers. By using the leaderboard feature, you acknowledge and consent to the following:
        </p>
        <p>
          When you achieve a score in the game, your score, along with your username or player ID, wagered amount, and prize earned will be automatically uploaded to our servers and displayed on the global leaderboard.
        </p>

        <p>
          The leaderboard will display your username or player ID, score, wagered amount, and prize earned to other users of the game. This information will be visible to anyone who accesses the leaderboard.
        </p>
        <p>
          The scores, wagered amounts, and prizes earned displayed on the leaderboard will be retained on our servers for as long as the leaderboard feature is active.
        </p>
        <p>
          By using our mobile game, you expressly consent to the collection, storage, and display of your scores, wagered amounts, and prizes earned as described above. The leaderboard is an integral part of the game experience and cannot be opted out of.
        </p>



        <h2>3. STORAGE LOCATION AND TRANSFER OF PERSONAL INFORMATION
        </h2>

        <p>Nazdar stores its data, including Personal Information, on servers located in Canada and the United States. By submitting information, you agree to this transfer, storing or processing of your Personal Information in Canada and the U.S. You acknowledge and agree that your Personal Information may be accessible to law enforcement and governmental agencies in those countries under lawful access regimes or court order.</p>

        <h2>4. DISCLOSURE OF PERSONAL INFORMATION TO THIRD PARTIES</h2>
        <h3>4.1. Service Providers and Business Partners</h3>

        <p>We may from time to time employ other companies and people to perform tasks on our behalf and need to share Personal Information with them to provide the Service to you. Unless we tell you differently, such third parties do not have any right to use the Personal Information we share with them beyond what is necessary to assist us. This includes third party companies and individuals employed by us to facilitate our Service, including the provision of maintenance services, database management, App analytics and general improvement of the Service.</p>

        <h3>4.2. Business Transfers</h3>
        <p>We may disclose your Personal Information if we have reason to believe that disclosure is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other Users, or anyone else (including the rights or property of anyone else) that could be harmed by such activities, or if we believe in good faith that such disclosure is required by and in accordance with applicable laws.
        </p>


        <h3>4.3. As Permitted Under Applicable Laws</h3>
        <p>We may disclose your Personal Information if we have reason to believe that disclosure is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other Users, or anyone else (including the rights or property of anyone else) that could be harmed by such activities, or if we believe in good faith that such disclosure is required by and in accordance with applicable laws.

        </p>

        <h3>4.4. With Your Consent
        </h3>

        <p>If we need to use or disclose any Personal Information in a way not identified in this Privacy Policy, we will notify you and/or obtain your express consent as required under applicable privacy laws.
        </p>

        <h2>5. RETENTION</h2>
        <p>We will keep your Personal Information for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you.  We may retain certain data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally-identifiable data, account recovery, or if required by law. All retained Personal Information will remain subject to the terms of this Privacy Policy.
        </p>

        <h2>6. ACCESS, CORRECTION AND ACCURACY</h2>
        <p>You may access the Personal Information we hold about you to verify accuracy of your Personal Information, to have a general account of our uses of your Personal Information, and to correct your Personal Information as appropriate. Upon receipt of your written request, we will provide you with a copy of your Personal Information although in certain limited circumstances, we may not be able to make all relevant Personal Information available to you (for example, where that Personal Information also pertains to another User). In such circumstances, we will provide reasons for the denial to you upon request. We will make every reasonable effort to keep your Personal Information accurate, and to respond to your in a timely manner.
        </p>

        <h2>7. SECURITY</h2>
        <p>We use commercially reasonable efforts to store and maintain your Personal Information in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Personal Information that you provide to us. We have implemented procedures designed to limit the dissemination of your Personal Information to only such designated staff and third parties as reasonably necessary to carry out the stated purposes we have communicated to you.</p>

        <h2>8. ADDITIONAL INFORMATION
        </h2>

        <p>If you want to enforce any of your rights under this Privacy Policy or if you have any questions regarding this Privacy Policy or your Personal Information, please contact our Privacy Officer per the information below:
        </p>

        <p><strong>Via Email:</strong> Email us at privacy@nazdar.games using the subject line “Re Privacy Policy”.
        </p>
        <p><strong>Via Mail:</strong> Mail us correspondence to 653 Village Drive, Sherwood Park, AB, T8A 4K7, Canada.
        </p>

      </div>

    </>
  )
}

export default IndexPage
