import * as React from 'react'

import { Container } from '../Container'
import { FullScreenSection } from '../FullScreenSection'
import styles from '../Features/Features.module.scss'

import Mailchimp from 'react-mailchimp-form'

export const Epilogue: React.SFC = () => (
  <FullScreenSection name="epilogue">
    <Container>

      <div className={styles.columnBox}>
        <h1 className={styles.center}>
          BE IN THE LOOP
        </h1>
        <p className={styles.center}>Join our mailing list to keep up to date.</p>



        <Mailchimp
          action="https://games.us21.list-manage.com/subscribe/post?u=d74959ad84798a72e915d1dd3&amp;id=a8c105a709"
          fields={[
            {
              name: "EMAIL",
              placeholder: "Email",
              type: "email",
              required: true,
            },
          ]}
          className={styles.mailchimp}
        />


      </div>

      {/* <form method="POST" action="https://api.web3forms.com/submit">

        <input type="hidden" name="access_key" value="2b9802c9-871a-4016-8f15-b386c62bdd4b" />

        <input type="text" name="name" />
        <input type="email" name="email" />
        <input type="hidden" name="redirect" value="https://nazdar.games/"></input>
        <textarea name="message"></textarea>
        <button type="submit">Submit Form</button>
      </form> */}


    </Container>
  </FullScreenSection>
)
