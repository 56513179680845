import * as React from 'react'
import classnames from 'classnames'

import { Container } from '../Container'
import { Row } from '../Row'
import { Col } from '../Col'
import { FullScreenSection } from '../FullScreenSection'
import { UnsplashCreditBadge } from '../UnsplashCreditBadge'

import styles from './Features.module.scss'

import RucksackMagazine from './rucksack-magazine-386319.jpg'
import RyanSearle from './ryan-searle-345035.jpg'

import appstore from '../../../public/appstore.svg'
import leap1 from '../../../public/leap-sm-1.jpg'
import leap2 from '../../../public/leap-sm-2.jpg'
import leap3 from '../../../public/leap-sm-3.jpg'
import leap4 from '../../../public/leap-4.jpg'
import pp1 from '../../../public/pp1.jpeg'
import appstoreWhite from '../../../public/appstore-white.svg'
import appstoreBlack from '../../../public/appstore-black.svg'
import { link } from 'fs'


export const Features = () => (
  <FullScreenSection name="features">


    <Container>
      <Row style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Col>
          <h1>OUR GAMES</h1>

          <div className={classnames(styles.featureBox, styles.guessWhat)}>


            <h2 className={styles.center} style={{ color: 'black' }}>Pegasus Payday</h2>
            <p className={styles.center}>Platform(s): <span className={styles.leapName}>iPhone / iPad</span></p>

            {/* <img className={classnames(styles.leapImage, styles.mobileOnly)} alt="Leap 1" src={leap1} /> */}
            {/* <a href="https://apps.apple.com/ca/app/pegasus-payday/id6468271886"><img className={styles.center} alt="Appstore Link" src={appstoreWhite} /></a> */}
            <p>
              <a className={styles.appstoreLink} target="_blank" href="https://apps.apple.com/ca/app/pegasus-payday/id6468271886"><img className={styles.appstore} alt="Appstore Link" src={appstoreWhite} /></a>


            </p>

            <p>
              <span className={styles.leapName}>Pegasus Payday</span> is a pinball-style peg blasting game that keeps you on the edge of your seat as you aim to beat your high score! Challenge yourself or compete against others around the globe for points.
            </p>

            <p>
              Embark on an journey where every peg counts. Your mission? Hatch the Pegasus eggs to clear the green pegs to advance through each level.


            </p>

            <p>
              But that’s not all! Catch the Pegasus egg for extra shots, adding an edge to your escapades.
            </p>

            <p>
              And for those with a flair for flair? Multi-peg combos send your score soaring, drenching your game in bonus points.
            </p>

            <p>Features:</p>
            <ul>

              <li>
                Unique peg blasting game!
              </li>

              <li>
                Challenge yourself with skill based gaming!
              </li>

            </ul>

          </div>
        </Col>
        <Col>
          <div className={styles.featureImageBox}>
            <img className={styles.leapImage} alt="Pegasus 1" src={pp1} />

          </div>



        </Col>
      </Row>



    </Container>


    <Container>
      <Row style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Col>

          <div className={classnames(styles.featureBox, styles.guessWhat)}>


            <h2 className={styles.center} style={{ color: 'black' }}>Leaping Legends</h2>
            <p className={styles.center}>Platform(s): <span className={styles.leapName}>iPhone / iPad

            </span></p>
            <p>
              <a target="_blank" href="https://apps.apple.com/us/app/leaping-legends/id6449751047"><img className={styles.appstore} alt="Appstore Link" src={appstoreWhite} /></a>


            </p>

            <img className={classnames(styles.leapImage, styles.mobileOnly)} alt="Leap 1" src={leap1} />


            <p>
              <span className={styles.leapName}>Leaping Legends</span> is a
              retro-style platformer that challenges players to conquer 48 levels across 6 worlds.
            </p>

            <p>  Easy to pick up for the casual gamer, but with layers of depth for hardcore players and completionists.
            </p>

            <p>
              Play as one of 12 Legends, heroes with unique abilities, and defeat dozens of different enemies to rescue the king.
            </p>

            <p>
              Embark on a heroic journey, save the kingdom, and become a legend in your own right in this thrilling side-scroller.
            </p>

            <p>Features:</p>
            <ul>

              <li>
                Family-friendly, ad-free experience
              </li>
              <li>
                Support for iOS, ipadOs, and MacOS
              </li>
              <li>Bluetooth controller support</li>


            </ul>

          </div>
        </Col>
        <Col>
          <div className={styles.featureImageBox}>
            <img className={classnames(styles.leapImage, styles.desktopOnly)} alt="Leap 1" src={leap1} />
            <img className={styles.leapImage} alt="Leap 2" src={leap2} />
            <img className={styles.leapImage} alt="Leap 3" src={leap3} />

          </div>



        </Col>
      </Row>



    </Container>



  </FullScreenSection>
)
