import * as React from 'react'

import { Container } from '../Container'

import styles from './Footer.module.scss'

import { SocialIcon } from 'react-social-icons';
import nazdarRed from '../../../public/Nazdar-Red.png'
import { animateScroll } from 'react-scroll'


const scrollToTop = () => {
  animateScroll.scrollToTop()
}

export const Footer = () => (
  <footer className={styles.footer}>
    <Container>
      <hr className={styles.footerRule} />

      <div className={styles.flex}>

        <div>
          <p>
            <small>
              Nazdar Games Inc.
            </small>
          </p>
          <p>
            <small>
              <a href="mailto: hello@nazdar.games">hello@nazdar.games</a>
            </small>
          </p>
          <p className={styles.copyrightLabel}>
            <small>
              © Copyright 2024
            </small>
          </p>

          <p>
            <small>
              <a href="/privacy">Privacy Policy</a>
            </small>
          </p>

          <p>
            <small>
              <a href="/tos">Terms of Service</a>
            </small>
          </p>

        </div>

        <div>
          <SocialIcon target="_blank" className={styles.socialMedia} url="https://www.linkedin.com/company/nazdar-games/" />
          <SocialIcon target="_blank" className={styles.socialMedia} url="https://twitter.com/nazdargames" />
          <SocialIcon target="_blank" className={styles.socialMedia} url="https://old.reddit.com/r/NazdarGames/" />
        </div>

      </div>

      <img title='Back to top' onClick={scrollToTop} className={styles.nazdarRed} src={nazdarRed} />

      <p title="Slava Ukraini" className={styles.center} >
        <a style={{ textDecoration: "none" }} target="_blank" href="https://donate.redcrossredcrescent.org/ua/donate/~my-donation">🇺🇦 We support the Ukrainian Red Cross.</a>
      </p>

      {/* <p title="Privacy Policy" className={styles.center} >
        <a style={{ textDecoration: "none" }} target="_blank" href="/privacy">Privacy Policy</a>
      </p> */}

    </Container>
  </footer>
)
