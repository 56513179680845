exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".MastheadNavLink-module--mastheadNavLink--2v8Xt {\n  display: inline-block;\n  position: relative;\n  line-height: 60px;\n  padding: 0 1rem;\n  font-weight: 300; }\n  .MastheadNavLink-module--mastheadNavLink--2v8Xt:hover {\n    cursor: pointer; }\n  .MastheadNavLink-module--mastheadNavLink--2v8Xt.MastheadNavLink-module--active--2dItO {\n    color: #0572ff;\n    border-bottom: 3px solid #0572ff; }\n\n/*# sourceMappingURL=sass.map */", "", {"version":3,"sources":["/root/nazdar-website/src/components/Masthead/MastheadNavLink/MastheadNavLink.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAqB;EACrB,mBAAkB;EAClB,kBAAiB;EACjB,gBAAe;EACf,iBAAgB,EAAA;EALlB;IAQI,gBAAe,EAAA;EARnB;IAYI,eAAc;IACd,iCAAgC,EAAA;;AADpC,gCAA+B","file":"MastheadNavLink.module.scss","sourcesContent":[null],"sourceRoot":""}]);

// exports
exports.locals = {
	"mastheadNavLink": "MastheadNavLink-module--mastheadNavLink--2v8Xt",
	"active": "MastheadNavLink-module--active--2dItO"
};