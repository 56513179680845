import * as React from 'react'
import classnames from 'classnames'

import { Container } from '../Container'
import { Row } from '../Row'
import { Col } from '../Col'
import { FullScreenSection } from '../FullScreenSection'
import { UnsplashCreditBadge } from '../UnsplashCreditBadge'

import styles from '../Features/Features.module.scss'


export const News = () => (
  <FullScreenSection name="news">

    <div className={styles.featureFullscreenBackground}>
      <Container>
        <Row>
          <Col>
            <div className={styles.innerBox}>
              <h2>NEWS</h2>
              <h4>July 18, 2024</h4>

              <p>
                Stay tuned for more information on release dates and where to find our latest games.



              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.credit}>
        <UnsplashCreditBadge
          src="https://unsplash.com/@stairhopper?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
          author="Alex Holyoake"
        />
      </div>
    </div>
  </FullScreenSection>
)
