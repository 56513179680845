exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Row-module--row--2m96w {\n  display: flex;\n  flex-wrap: wrap;\n  margin-right: -1rem;\n  margin-left: -1rem; }\n  .Row-module--row--2m96w.Row-module--reversed--2xMJq {\n    flex-direction: row-reverse; }\n\n/*# sourceMappingURL=sass.map */", "", {"version":3,"sources":["/root/nazdar-website/src/components/Row/Row.module.scss"],"names":[],"mappings":"AAGA;EACE,cAAa;EACb,gBAAe;EACf,oBAAmB;EACnB,mBAAkB,EAAA;EAJpB;IAOI,4BAA2B,EAAA;;AAF/B,gCAA+B","file":"Row.module.scss","sourcesContent":[null],"sourceRoot":""}]);

// exports
exports.locals = {
	"row": "Row-module--row--2m96w",
	"reversed": "Row-module--reversed--2xMJq"
};