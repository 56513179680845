import * as React from 'react'
import Helmet from 'react-helmet'

import { Intro } from '../components/Intro'
import { About } from '../components/About'
import { Features } from '../components/Features'
import { Epilogue } from '../components/Epilogue'
import { News } from '../components/News'

import { Footer } from '../components/Footer'
import IndexLayout from '../layouts'
import styles from '../components/Intro/Intro.module.scss'

const IndexPage: React.FC = () => {
  return (
    <>
      <h1> TERMS OF SERVICE</h1>
      <p> Effective Date: [9/20/2023] <br />
        Last Updated: [3/4/2024]
      </p>

      <p>
        PLEASE READ THESE TERMS OF SERVICE ("TERMS") CAREFULLY BEFORE USING SERVICES (COLLECTIVELY, THE "SERVICES"). THESE TERMS GOVERN THE LEGAL RELATIONSHIP BETWEEN NAZDAR GAMES INC (”NAZDAR GAMES”) AND ANY THIRD PARTY (A “USER”).      </p>


      <p>Nazdar Games Inc. (“Nazdar, “we”, “us”, “our”) designs owns and operates Pegasus Payday, a skill-based, mobile game allowing players to participate in online gaming challenges, competitions, and tournaments (“App”) and supporting software (together with App, “Service”) and makes the Service available to end users (“User”, “you, “your”).

      </p>

      <p>
        By using the Service, you indicate that you have read and accepted these Terms, which constitute a binding legal agreement between you and Nazdar. If you do not accept these Terms, then do not use the Service.
      </p>

      <p>
        These Terms may be amended or updated by us from time to time at our sole discretion. If we make changes to these Terms, we will notify you by updating the “Last Updated” date at the top of these Terms, posting a notice via the Service, and/or by sending you an email (as appropriate). Any revisions to these Terms shall become effective thirty (30) days following the date of the notice and your acceptance of the revised Terms or decision to continue using the Service thereafter (as applicable) shall constitute your acceptance of the revised Terms. Nazdar may require you to provide consent to the updated Terms before continuing to use the Service. If you do not agree to any such change(s), you shall stop using the Service. Otherwise, your continued use of the Service constitutes your acceptance of such change(s)
        Please be aware that Section 9 of these Terms contains provisions governing how claims that you and we have against each other are resolved. In particular, it contains an arbitration agreement which will, with limited exceptions, require disputes between us to be submitted to binding and final arbitration. Unless you opt out of the arbitration agreement: (1) you will only be permitted to pursue claims and seek relief against us on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding; and (2) you are waiving your right to seek relief in a court of law and to have a jury trial on your claims.

      </p>


      <h2>1. Registration. </h2>

      <p>
        <strong>1. App Store and Mobile Device Terms.</strong> You may access Pegasus Payday by downloading the App via a third-party application marketplace (each an “App Store”). You agree that you will only use the App: (i) on the branded device of the applicable App Store, if required by the App Store’s terms and conditions; and (ii) as permitted by any usage rules set forth in the applicable App Store’s terms of service. You acknowledge that these Terms are concluded between Nazdar and you only, and App Store has no obligation or liability to you with respect to the App or these Terms. You acknowledge and agree that App Store is a third-party beneficiary to these Terms as it relates to the App Store’s provision of the App to you. To the extent you download or access the App through a mobile device, your wireless service carrier’s standard charges, data rates, and other fees may apply.
      </p>

      <p>
        <strong>2. Eligibility.</strong> To use the Service, you must register an account (“Account”) and provide certain information about yourself as prompted by the registration form. A User may only have and maintain one Account. ANY ATTEMPT TO CREATE MORE THAN ONE ACCOUNT IS A BASIS FOR IMMEDIATE TERMINATION OF ALL ACCOUNTS AND THE FORFEITURE OF ALL BALANCES AND VIRTUAL ITEMS ASSOCIATED WITH SUCH ACCOUNTS. You represent and warrant that: (i) all required registration information you submit is truthful and accurate; (ii) you will maintain the accuracy of such information at all times; (iii) you have not been previously banned by Nazdar from any Nazdar Offerings for any reason; and (iv) you will not allow any other person to access your Account or use your Account to access the Service or accept or use prizes, winnings, Virtual Items, digital trophies or other representations of value (“Winnings”). To participate in Competitions, Users will be required to meet additional eligibility criteria in Section 7 below.
      </p>

      <p>
        <strong>3. Verification of Account Information.</strong> Nazdar reserves the right to verify the eligibility of any User at any time, during or after Account creation, including by asking the User to provide identification or verify the User’s age and/or physical location. If you do not provide accurate eligibility information, or if Nazdar cannot reasonably verify your eligibility information as accurate, Nazdar may suspend or terminate your Account and you agree that you forfeit any balance and Virtual Items associated with your Account, if any. Nazdar may suspend or terminate your Account as provided in these Terms, regardless of whether you meet the eligibility criteria provided herein.
      </p>



      <h2>2. Services and Use.</h2>

      <p>
        <strong>1. User License.</strong> Nazdar hereby grants you a non-transferable, non-exclusive, limited license to use our Service for your personal, non-commercial, entertainment purposes. You agree not to use our Service for any other purpose. The rights granted to you are subject to your full compliance with these Terms.
      </p>


      <p>
        <strong>2. Reservation of Rights.</strong>The Service, including without limitation any software or code embedded therein, are the property of Nazdar and its licensors and are protected from unauthorized copying and dissemination by copyright law, trademark law, and other intellectual property laws. You acknowledge and agree that you have no right, license, or authorization with respect to any of the technology underlying the Service except as expressly set forth in these Terms. The Service is licensed to you and not sold. Except as expressly stated herein, nothing in these Terms gives you a right to use Nazdar’s names, trademarks, logos, domain names, and other distinctive brand features without our prior written consent.
      </p>


      <p>
        <strong>3. User Content License.</strong>By uploading any information or data (collectively, “User Content”) to the Service, you grant us (and our affiliated companies and our and their agents) a non-exclusive, royalty-free, paid-up, irrevocable, transferable, worldwide license (with the right to sublicense) to use, copy, modify, transmit, display, distribute and otherwise exploit such User Content: (i) to the extent necessary to provide the Service and meet our obligations to you; (ii) to meet our legal obligations; and (iii) to improve the Service and other Nazdar Offerings (defined below).
      </p>


      <p>
        <strong>4. User Content Restrictions.</strong>You represent and warrant that you are solely responsible for (i) obtaining all necessary licenses, permissions, and consents to ensure User Content can be shared with and processed by Nazdar as contemplated herein; (ii) the accuracy, quality, integrity, legality, reliability, and appropriateness of User Content; (iii) the consequences of sharing User Content via the Service to which you do not have rights in or title to; and (iv) ensuring that uploading User Content via the Service does not violate any applicable laws or the rights of any third party. Although Nazdar does not monitor all User Content, we reserve the right to edit, modify or remove any User Content or refuse to host or process any User Content which we reasonably consider to be in breach applicable laws or these Terms or our other policies.
      </p>




      <p>
        <strong>5. Feedback.</strong> If you provide us with any suggestions, comments or other feedback relating to any aspect of the Service ("Feedback"), we may use and incorporate such Feedback in the Service or in any other Nazdar products or services (collectively, "Nazdar Offerings"). Accordingly, you agree that: (i) Nazdar is not subject to any confidentiality obligations in respect to the Feedback; (ii) you have all of the necessary rights to disclose the Feedback to Nazdar; (iii) Nazdar (including all of its successors and assigns and any successors and assigns of any of the Nazdar Offerings) may freely use, reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any Nazdar Offerings; and (iv) you are not entitled to receive any compensation or re-imbursement of any kind from Nazdar or any third party in respect of the Feedback.
      </p>

      <h2>3. Restrictions.</h2>




      <p>
        <strong>1. Our Rights.</strong> Nazdar reserves the right, at all times and at its sole discretion (but will have no obligation), to terminate or reclaim Accounts, or to limit an Account’s access to some or all aspects of the Service. Nazdar shall have the right to access, read, preserve, and disclose any information (including User Content) as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce these Terms, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to User support requests, or (v) protect the rights, property or safety of our other Users and the public.
      </p>


      <p>
        <strong>2. Restrictions.</strong>You agree not to:
      </p>



      <ul>
        <li>1. Use the Service for any commercial purpose without our prior written consent.</li>
        <li>2. Use cheats, hacks, or any unauthorized means to gain an advantage in the Service.</li>
        <li>3. Harass, abuse, threaten, impersonate or harm other Users or attempt to do so.</li>
        <li>4. Interfere with or disrupt the Service or servers or networks connected to the Service or disobey any requirements, procedures, policies or regulations of networks connected to the Service.</li>
        <li>5. Use the Service to infringe upon any intellectual property rights of Nazdar or any third party.
        </li>
        <li>6. Intentionally or unintentionally violate any applicable local, state, provincial, national, or international law or regulation.
        </li>
        <li>7. License, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Service.
        </li>
        <li>8. Use profanity, obscenities, or the use of **asterisks** or other “masking” characters to disguise offensive words in your Account name.
        </li>
        <li>9. Modify, translate, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any software provided as part of the Service.
        </li>
        <li>10. Upload any User Content that: (I) is unlawful or invasive of another's privacy; (II) you do not have a right to make available under any law or under a contractual relationship; (III) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or data or the Service or that of any Users of the Service or that compromises anyone’s privacy; or (IV) contains any falsehoods or misrepresentations or creates an impression that you know is incorrect, misleading, or deceptive, or any material that could damage or harm minors in any way.
        </li>

      </ul>


      <h2>Virtual Items.
      </h2>

      <p><strong>In-App Purchases.</strong> Nazdar may make certain virtual items ("Virtual Items"). Available for purchase through the Service from time to time. Virtual Items are non-transferrable and non-refundable, and are for your personal, non-commercial use only. You agree that Virtual Items have no monetary value outside of the Service and cannot be exchanged for real money or other goods or services.
      </p>

      <p><strong>Ownership.</strong> Nazdar retains full ownership of Virtual Items, and you have no ownership rights or interest in them. Any balance of Virtual Items does not constitute stored value. Virtual Items cannot be transferred between or sold to Accounts.
      </p>

      <p><strong>Modification or Removal.</strong> Nazdar Games reserves the right to modify, change, or remove Virtual Items or change the pricing of Virtual Items without notice and without any liability to you.
      </p>

      <h2>5. Privacy and Data Collection.</h2>
      <p>Your use of the Service is subject to our Privacy Policy, which details our practices regarding the collection, use, and disclosure of your personal information. By using the Service, you consent to the collection and processing of your data as described in the Privacy Policy.</p>

      <h2>6. Third Party Services. </h2>

      <p>The Service may integrate with, provide suggestions to, or contain links to third-party websites, platforms, products, or services that are not owned or controlled by Nazdar (collectively,“<strong>Third-Party Services</strong>”). In no event shall any integration with or reference to a Third-Party Service or any product available thereon be construed as an approval or endorsement by Nazdar of that Third-Party Service or applicable product. Third-Party Services are subject to the terms and conditions governing use of the applicable Third-Party Services, and you are solely responsible for complying with those terms and conditions.</p>

      <h2>7. Competitions.</h2>

      <p>
        <strong>a. Eligibility.</strong> Nazdar allows Users to redeem and use real funds (“Funds”) to particulate in gaming challenges, competitions and tournaments (“Competitions”) via the App. To particulate in Competitions, you must have a valid Nazdar Account registered in accordance with Section 1 above and you must also meet the following additional criteria: (i) you are at least eighteen (18) years old; (ii) you are physically located in an Eligible Location (defined below); and (iii) you have a personal United States telephone number. Each time you use the Service to participate in a Competition, you must accurately confirm you are in an Eligible Location.
      </p>

      <p>
        <strong>b. Eligible Location.</strong> “Eligible Location(s)” means a location within one of the following states or jurisdictions within the United States: Alabama, Alaska, California, Colorado, the District of Columbia, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Kansas, Maryland, Massachusetts, Michigan, Minnesota, Missouri, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, Texas, Utah, Vermont, Virginia, West Virginia, Washington, Wisconsin, and Wyoming. The Eligible Locations may be subject to change at any time at Nazdar’s sole discretion. In the event a location from which you are participating in a Competition is no longer an Eligible Location, the Competition will end, and all Funds will be returned.
      </p>

      <p>
        <strong>c. Funds</strong> By providing a payment method for Funds, you (i) represent that you are authorized to use the payment method that you provided and that any payment information you provide is true and accurate; (ii) authorize us to charge you for the Funds using your payment method. We may use a Third-Party Service to process payments for Funds. All Funds are stated in U.S. dollars, must be prepaid and are non-refundable. You are fully responsible and liable for all charges, deposits and withdrawals made under your Account, including any unauthorized charges, deposits or withdrawals.
      </p>

      <p>
        <strong>d. Cash Deposits.</strong> If you play in a Competition that requires Funds to be paid as an entry (“Cash Competition”), you must submit and maintain at all times the following current and correct information: your full name, your permanent residential address, your phone number and your credit card or other payment information. Participating in Cash Competitions may require establishing a positive Account balance in any amount we determine.
      </p>

      <p>
        <strong>e. Withdrawals.</strong> You may request a withdrawal of funds from your available Account balance at any time. Processing of requested funds is made by refund to the payment method used to make your deposit and may take up to ninety (90) days; provided, however, that we may freeze your Account and/or delay a request for withdrawal of Funds pending completion of any investigation of reported or suspected abuse, verification of eligibility or to comply with applicable laws.
      </p>

      <p>
        <strong>f. Winnings.</strong> If you are eligible to receive Winnings, we may require that you provide proof that you are or were at the time of your participation in the subject Competition, eligible to participate in Competitions in accordance with these Terms and that your participation in Competitions was in accordance with these Terms. If you do not provide such proof, then you will not receive the relevant Winnings.
      </p>

      <h2>8. Governing Law.</h2>
      <p>Any dispute or claim relating in any way to your use of the Service will be governed and interpreted by and under the laws of Ontario, Canada without giving effect to any principles that provide for the application of the law of any other jurisdiction. The United Nations Convention on Contracts for the International Sale of Goods is expressly excluded from these Terms. Each of the parties hereby attorns to the exclusive jurisdiction of the provincial and federal courts located in the Province of Ontario with respect to any dispute or claim arising out of or in connection with these Terms.</p>


      <h2>9. Dispute Resolution and Binding Arbitration.</h2>

      <p>​PLEASE READ THE FOLLOWING ARBITRATION AGREEMENT (“ARBITRATION AGREEMENT”) CAREFULLY BECAUSE IT REQUIRES YOU AND NAZDAR TO ARBITRATE CERTAIN DISPUTES AND CLAIMS AND LIMITS THE MANNER IN WHICH WE CAN SEEK RELIEF FROM EACH OTHER. ARBITRATION PRECLUDES YOU AND NAZDAR FROM SUING IN COURT OR HAVING A JURY TRIAL. THIS WOULD PREVENT YOU FROM PARTICIPATING IN A CLASS ACTION LAWSUIT THAT WAS FILED AGAINST NAZDAR THAT IS DESCRIBED BELOW, AS WELL AS OTHER CLASS ACTION LAWSUITS. YOU AND NAZDAR AGREE THAT ARBITRATION WILL BE SOLELY ON AN INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION, CLASS ACTION, OR ANY OTHER KIND OF REPRESENTATIVE PROCEEDING. NAZDAR AND YOU ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY.</p>

      <p>FOLLOW THE INSTRUCTIONS BELOW IF YOU WISH TO OPT OUT OF THE REQUIREMENT OF ARBITRATION ON AN INDIVIDUAL BASIS. NO CLASS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE ALLOWED UNDER THIS ARBITRATION AGREEMENT.
      </p>


      <p><strong>a. Claims Covered by Arbitration.</strong>All disputes, claims or controversies arising out of or relating to these Terms, the Service, a Competition, or any aspect of your relationship with Nazdar (“Disputes”) shall be determined exclusively by binding arbitration. This includes claims that accrued before you entered into these Terms. The only Disputes not covered by this section are (i) disputes regarding the infringement, protection, or validity of your, Nazdar’s or Nazdar’s licensors’ copyright, trademark or patent rights and (ii) individual disputes that qualify for small claims court.
      </p>



      <p><strong>b. Informal Negotiations.</strong>Nazdar is always interested in resolving disputes amicably and efficiently, and most User concerns can be resolved quickly and to your satisfaction by emailing customer support at privacy@nazdar.games. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, a written notice (“Notice of Dispute”). The Notice of Dispute must: (i) include the full name and contact information of the complaining party or legal representative of the complaining party; (ii) describe the nature and basis of the Dispute; and (iii) set forth the specific relief sought. Nazdar will send its Notice of Dispute to your email address. You will send your Notice of Dispute to privacy@nazdar.games. If Nazdar and you do not resolve the Dispute within sixty (60) calendar days after the Notice is received, you or Nazdar may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by Nazdar or you will not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or Nazdar are entitled.
      </p>

      <p><strong>c. Binding Arbitration.</strong> If you and Nazdar Games cannot resolve any Dispute by informal negotiation, you agree to resolve all Disputes by binding arbitration at ADR Chambers pursuant to the general ADR Chambers Rules for Arbitration. The arbitration shall be conducted before a single neutral arbitrator in the City of Toronto. The arbitration shall proceed in accordance with the provisions of the Arbitration Act (Ontario). If the arbitrator finds that you cannot afford to pay ADR Chambers’ filing, administrative, hearing, and/or other fees and cannot obtain a waiver from ADR Chambers, Nazdar will pay them for you. In addition, Nazdar will reimburse all such ADR Chambers’ filing, administrative, hearing, and/or other fees for Disputes totaling less than CAD $5,000 unless the arbitrator determines the claims are frivolous.
      </p>


      <p><strong>d. Authority of Arbitrator.</strong> The decision arrived at by the arbitrator(s) shall be final and binding and no appeal shall lie therefrom. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator will have the authority to grant any remedy or relief that would otherwise be available in court. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction. The costs of the arbitrator shall be divided equally between the parties.
      </p>

      <p><strong>e. Waiver of Jury Trial.</strong> YOU AND NAZDAR HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Nazdar are instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 9(a) above.
      </p>

      <p><strong>f. Waiver of Class or Other Non-Individualized Relief.</strong> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. If a decision is issued stating that applicable law precludes enforcement of any of this subsection’s limitations as to a given Dispute for relief, then the Dispute must be severed from the arbitration and brought into the courts located in the Province of Ontario. All other Disputes shall be arbitrated.
      </p>


      <p><strong>g. Opt-Out.</strong> You have the right to opt out of binding arbitration within 30 days of the date you first accepted these Terms by providing us with notice of your decision to opt-out via email at privacy@nazdar.games. To be effective, the opt-out notice must include your full name, mailing address, and email address. The notice must also clearly indicate your intent to opt out of binding arbitration. If you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
      </p>

      <p><strong>h. Severability.</strong> Except as provided in section 9(g), if any part of this Arbitration Agreement (other than the section (f) above) are found under the law to be invalid or unenforceable, the parties agree to replace such part with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable part, and this Arbitration Agreement will be enforceable as so modified. If any of the provisions of section (f) above titled are invalid or unenforceable, then the entirety of this Arbitration Agreement will be null and void, unless such provisions are deemed to be invalid or unenforceable solely with respect to claims for public injunctive relief. The remainder of these Terms will continue to apply.
      </p>


      <p><strong>i. Modification.</strong> Notwithstanding any provision in this Agreement to the contrary, we agree that if Nazdar makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective via email at privacy@nazdar.games. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).
      </p>

      <h2>10. DISCLAIMERS.</h2>
      <p>THE SERVICE AND ALL INFORMATION PROVIDED THEREON ARE PROVIDED "AS IS." NAZDAR SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, EITHER EXPRESS, IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE. USE OR RELIANCE ON THE SERVICE IS DONE SO AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY RESULTING DAMAGES OR LOSSES. NAZDAR DOES NOT WARRANT, ENDORSE, GUARANTEE, PROVIDE ANY CONDITIONS OR REPRESENTATIONS, OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY ANY THIRD PARTY THROUGH THE SERVICE OR IN RESPECT TO ANY WEBSITE THAT CAN BE REACHED FROM A LINK ON THE SERVICE OR FEATURED IN ANY ADVERTISING ON THE SERVICE. NAZDAR SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO WITH ANY SUCH THIRD PARTY.
      </p>

      <h2>11. LIMITATION OF LIABILITY.</h2>
      <p>WE, OUR REPRESENTATIVES, AFFILIATES, LICENSORS, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, REGARDLESS OF THE CAUSE AND WHETHER ARISING IN CONTRACT, TORT, OR OTHERWISE, RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM: (I) YOUR USE OF, YOUR INABILITY TO USE, OR YOUR RELIANCE ON THE SERVICE OR INFORMATION AVAILABLE THEREON; (II) MISTAKES, OMISSIONS OR INACCURACIES IN OR ON THE SERVICE; (III) YOUR USE OF OR RELIANCE ON ANY THIRD-PARTY SERVICES; (IV) ANY BODILY INJURY (INCLUDING DEATH) RESULTING FROM USE OR RELIANCE ON THE SERVICE OR INFORMATION AVAILABLE THEREON (V) THE ACTS, OMISSIONS, OR CONDUCT OF ANY THIRD-PARTY; (VI) THE COST OF PROCURING SUBSTITUTE GOODS, INFORMATION OR SERVICES; AND (VII) ANY OTHER LOSSES OR DAMAGES OF ANY KIND ARISING FROM OR RELATING TO YOUR USE OF THE SERVICES. THESE LIMITATIONS SHALL APPLY EVEN IF NAZDAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL NAZDAR’S AGGREGATE LIABILITY FOR DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS EXCEED ONE HUNDRED CANADIAN DOLLARS ($100). THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENSION PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
      </p>

      <h2>12. Indemnification.</h2>


      <p><strong>a. Indemnification by User.</strong>  To the extent permitted under applicable law, you shall, at your own expense, indemnify and hold Nazdar and its subsidiaries, affiliates, representatives, successors and assigns (“Indemnified Parties”) harmless from all claims, actions, proceedings, demands, damages, losses, costs, and expenses (including reasonable attorneys’ fees) awarded against or incurred by Indemnified Parties as a result of any third party claim arising in connection Competitions, User Content uploaded by you through the Service and/or any violation by you of these Terms, the rights of Nazdar or any third party, or any applicable law or regulation. For the purposes of Section 12a, you acknowledge that Nazdar is acting as agent and trustee for Indemnified Parties.
      </p>


      <p><strong>b. Indemnification Conditions.</strong> Nazdar will provide notice to you of any claim, suit, or proceeding requiring indemnification in accordance with Section 12. Nazdar reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under Section 12, and you agree to cooperate with any reasonable requests assisting Nazdar’s defense of such matter. Section 12 does not require you to indemnify Nazdar for any unconscionable commercial practice by Nazdar or for Nazdar’s gross negligence, fraud, deception, false promise, misrepresentation or concealment, suppression, or omission of any material fact in connection with the Service.
      </p>

      <h2>13. Termination.</h2>



      <p><strong>a. Termination by you and Nazdar.</strong> You may terminate these Terms at any time and for any reason by discontinuing use of the Service. Nazdar may, under certain circumstances and without prior notice, immediately terminate your ability to access the Service or portions thereof. Cause for such termination shall include, but not be limited to: breaches or violations of these Terms or any other agreement that you may have with Nazdar (including, without limitation, non-payment of any fees owed by you to Nazdar); requests by law enforcement or other government agencies; a request by you; discontinuance or material modification to the Service (or any part thereof); unexpected technical, security or legal issues or problems; and/or participation by you, directly or indirectly, in fraudulent or illegal activities.
      </p>


      <p><strong>b. Effect of Termination.</strong> Your rights to use and access the Service and all licenses granted by Nazdar to you herein will immediately end upon termination of your access to the Service and may result in deletion of your Account. Sections 1a, 2b-e, 3, 4, 6-12, 13b, 14, and 17 of these Terms shall survive termination. Termination shall not release you from payment of any unpaid fees owed to Nazdar incurred on and up to the date of termination.
      </p>

      <h2>14. Confidentiality.</h2>

      <p><strong>a. General.</strong> “Confidential Information” means all confidential and proprietary information of a party (the “Disclosing Party”) disclosed to the other party (the “Receiving Party”) in connection with the Services, whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure, including but not limited to business and marketing plans, know-how, technology, technical and financial information, product designs, and business processes. Confidential Information shall not include any information that: (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party; (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (iii) was independently developed by the Receiving Party without breach of any obligation owed to the Disclosing Party; or (iv) is received from a third party without breach of any obligation owed to the Disclosing Party.
      </p>
      <p><strong>b. Protection.</strong> The Receiving Party shall not disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of these Terms, except with the Disclosing Party’s prior written permission. The Receiving Party agrees to protect the Confidential Information of Disclosing Party in the same manner that it protects its own Confidential Information (but in no event using less than reasonable care). If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party, it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance if the Disclosing Party wishes to contest the disclosure.
      </p>

      <p><strong>c. Remedies.</strong> If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential Information of the Disclosing Party in breach of these Terms, the Disclosing Party shall have the right, in addition to any other remedies available to it, to seek injunctive relief to enjoin such acts, it being specifically acknowledged by the parties that any other available remedies may be inadequate.
      </p>


      <h2>15. Security.</h2>
      <p>
        Information sent or received over the internet is generally unsecure and Nazdar cannot and does not make any representation or warranty concerning security of any communication to or from the Service, or any representation or warranty regarding the interception by third parties of information (including User Content) you may submit to us via the Service.
      </p>

      <h2>16. Availability.</h2>
      <p>
        Nazdar may alter, suspend, or discontinue all or parts of the Service at any time and for any reason or no reason, without notice. The Service may be unavailable from time to time due to maintenance or malfunction of computer or network equipment or other reasons. Nazdar may periodically add or update the information and materials on the Service without notice. Nazdar will use commercially reasonable efforts to make the Service available to you at all times except for: (i) planned downtime, including but not limited to maintenance; or (ii) any unavailability caused by circumstances beyond Nazdar’s reasonable control, including without limitation, malfunction of computer or network equipment, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, health pandemics, strikes or other labour problems.
      </p>

      <h2>17. General.</h2>
      <p>These Terms constitute the entire agreement between the parties relating to the Service and all related activities. If any part of these Terms is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions. The failure of Nazdar to exercise or enforce any right or provision under these Terms shall not constitute a waiver of such right or provision. Any waiver of any right or provision by Nazdar must be in writing and shall only apply to the specific instance identified in such writing. You may not assign these Terms, or any rights or licenses granted hereunder, whether voluntarily, by operation of law, or otherwise without Nazdar’s prior written consent. Nazdar may assign these Terms without your consent in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of Nazdar’s business, shares, or assets.
      </p>



    </>
  )
}

export default IndexPage
